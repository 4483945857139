/* Hero section styles */
.hero-container {
    position: relative;
    padding-top: 200px;
    padding-bottom: 32px;
    text-align: center;
  }
  
  .hero-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../assets/images/Gen/ai-image.webp);
    background-size: cover;
    background-position: center;
    z-index: -2;
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  
  /* Custom text typing animation */
  .typing-text {
    color: white;
  }
  
  /* Fade-up animation for text */
  .fade-up-text {
    opacity: 0;
    animation: fade-up 1s forwards;
  }
  
  @keyframes fade-up {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Custom button styles */
  .custom-button {
    background-color: gold;
    color: black;
  }
  
  .custom-button:hover {
    background-color: green;
    color: white;
  }
  